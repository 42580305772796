import { useTranslation } from "react-i18next";
import "../cart/cart.css";
import "./bankInfo.css";
// Icons
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { SiWhatsapp } from "react-icons/si";
import logo from "../../img/logo.png";
import Header from "../header/Header";

const BankInformation = () => {
  const { t } = useTranslation("translate");

  return (
    <section id="BankInfo">
      <Header />
      <main className="right_cont">
        <div className="info_cont">
          <div className="left_info">
            {/* <div className=''></div> */}
            <div>
              <p>{t("cart.name")}</p>
              <p>{t("cart.currency")}</p>
              <p>IBAN</p>
              <p>Hesap Adı</p>
            </div>
            <div>
              <p>Garanti Bankası</p>
              <p>TL</p>
              <p>TR76 0006 2000 4610 0006 2920 57</p>
              <p>Liwa Yazılım San. Tic. Ltd. Şti.</p>
            </div>
          </div>
        </div>
      </main>
      <section id="footer">
        <div className="footer-cont">
          <div className="footer-links">
            <div className="footer-1 link-cont">
              <div className="logo">
                <img src={logo} alt="Logo" />
                <p>Liwa Soft</p>
              </div>
              <div>
                {t("footer.p-1")}
                <br />
                <br />
                <div className="phone_div">
                  <a href="tel:0850 840 78 07">
                    <div className="footerPhone">
                      {" "}
                      {/* <MdOutlineAddIcCall /> */}
                      📱 0850 840 78 07
                    </div>
                  </a>
                  <a href="tel:0533 969 57 61">
                    <div className="footerPhone">
                      {" "}
                      {/* <MdOutlineAddIcCall />  */}
                      📱 0533 969 57 61
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-2 link-cont">
              <p>{t("footer.p-2")}</p>
              <a href="/">{t("header.home")}</a>
              <a href="#about">{t("header.aboutUs")}</a>
              <a href="#product">{t("header.products")}</a>
              <a href="https://forum.liwasoft.com/">Forum</a>
              <a href="cart">{t("header.cart")}</a>
              <a href="bank-info">{t("header.bankInfo")}</a>
            </div>

            <div className="footer-3 link-cont">
              <p>{t("header.products")}</p>
              <a href="products">{t("footer.a-1")}</a>
              <a href="products">{t("footer.a-2")}</a>
              <a href="products">{t("footer.a-3")}</a>
              <a href="products">{t("footer.a-4")}</a>
              <a href="products">{t("footer.a-5")}</a>
              <a href="products">{t("footer.a-6")}</a>
            </div>

            <div className="footer-4 link-cont">
              <p></p>
              <a href="products">{t("footer.a-7")}</a>
              <a href="products">{t("footer.a-8")}</a>
              <a href="products">{t("footer.a-9")}</a>
              <a href="products">{t("footer.a-10")}</a>
              <a href="products">{t("footer.a-11")}</a>
              <a href="products">{t("footer.a-12")}</a>

              <div className="social">
                <a href="https://www.facebook.com/liwasoft/" className="fb">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/liwasoft/" className="ins">
                  <FaInstagram />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCk9flKQF502tzxmDo_4VPbw"
                  className="yt"
                >
                  <IoLogoYoutube />
                </a>
                <a
                  href="https://web.whatsapp.com/send?phone=908508407807&text=Merhaba"
                  className="wa"
                >
                  <SiWhatsapp />
                </a>
              </div>
            </div>
          </div>

          <div className="footer-copy">
            <p>{t("footer.copy")}</p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BankInformation;
